<template>
    <div class="field" :class="getClasses()">
        <select :name="fieldName" class="form-field" v-if="fieldName === 'DOCUMENT_TYPE' || fieldName === 'CURRENCY'" v-model="value" @change="$emit('update:modelValue', value)">
            <option v-for="option in options" :key="option" :value="option">{{ getDictionary(option) }}</option>
        </select>
        <select :name="fieldName" class="form-field" v-else-if="fieldName === 'CARD_NUMBER' || fieldName === 'IBAN'" v-model="value" @change="$emit('update:modelValue', value)">
            <option v-for="option in options" :key="option.accountId" :value="option">{{ option.label }}</option>
        </select>
        <select v-else :name="fieldName" class="form-field" :class="{ error: v$.$error, success: v$.$dirty && !v$.$error }" v-model.trim="v$.$model">
            <template v-if="v$.$path === 'step2.country'">
                <option v-for="option in options" :key="option.country.code" :value="option.country">{{ option.country.name }}</option>
            </template>
            <template v-else-if="v$.$path === 'step2.dialCode' || v$.$path === 'dialCode'">
                <template v-if="configStore.config.skin === 'bo' || configStore.config.skin === 'lj'">
                    <option v-for="option in options" :key="option.country.code" :value="option.country">{{ option.country.dialCode }}</option>
                </template>
                <template v-else>
                    <option v-for="option in options" :key="option.country.code" :value="option.country">{{ option.country.code }} {{ option.country.dialCode }}</option>
                </template>
            </template>
            <template v-else-if="v$.$path === 'step2.currency'">
                <option v-for="option in options" :key="option.currencyCode" :value="option.currencyCode">{{ option.currencySymbol }}</option>
            </template>
            <template v-else-if="v$.$path === 'step1.dobDay' || v$.$path === 'startDay' || v$.$path === 'endDay'">
                <option v-for="option in getDays()" :key="option" :value="option">{{ option }}</option>
            </template>
            <template v-else-if="v$.$path === 'step1.dobMonth' || v$.$path === 'startMonth' || v$.$path === 'endMonth' || v$.$path === 'expiryMonth'">
                <option v-for="option in getMonths()" :key="option" :value="option">{{ option }}</option>
            </template>
            <template v-else-if="v$.$path === 'step1.dobYear' || v$.$path === 'startYear' || v$.$path === 'endYear' || v$.$path === 'expiryYear'">
                <option v-for="option in getYears()" :key="option" :value="option">{{ option }}</option>
            </template>
        </select>
        <label :for="fieldName" class="select-label" :class="{ notEmpty: !empty }">{{ getDictionary(fieldName) }}</label>
    </div>
</template>

<script>
export default {
    name: "select-field-component",
    emits: ["update:modelValue"],
    props: {
        v$: Object,
        fieldName: String,
        options: Array,
        location: Object,
        year: Number,
        month: String,
        defaultValue: String | Object,
        margin: String,
    },
    computed: {
        empty() {
            return this.v$ && (this.v$.$model === "" || this.v$.$model === null);
        },
    },
    data() {
        return {
            value: this.defaultValue,
        };
    },
    created() {
        if (this.location) {
            this.populateByLocation();
        }
    },
    methods: {
        getClasses() {
            let classes = this.configStore.config.formStyle === "old" ? "old-form-group" : "modern-form-group";
            classes = `${classes} ${this.margin === "yes" ? "me-1" : "me-0"}`;
            return classes;
        },
        populateByLocation() {
            if (this.v$.$path === "step2.country") {
                this.options.some((option) => {
                    if (option.country.name === this.location.country.name) {
                        this.v$.$model = this.location.country;
                    }
                });
            } else if (this.v$.$path === "step2.dialCode") {
                this.options.some((option) => {
                    if (option.country.dialCode === this.location.country.dialCode) {
                        this.v$.$model = this.location.country;
                    }
                });
            } else if (this.v$.$path === "step2.currency") {
                if (this.location.country.name === "Brazil") {
                    this.v$.$model = "BRL";
                } else if (this.location.country.name === "Norway") {
                    this.v$.$model = "NOK";
                } else if (this.location.country.name === "Poland") {
                    this.v$.$model = "PLN";
                } else {
                    this.v$.$model = "EUR";
                }
            }
        },
        getYears() {
            let years;
            if (this.v$.$path === "step1.dobYear") {
                // Remove 18 years due to legibility to play from current year
                years = [new Date().getFullYear() - 18];
                // Obtain a 100 year gap and push to array and return
                for (var i = 1; i < 82; i++) {
                    years.push(years[0] - i);
                }
            } else if (this.v$.$path === "expiryYear") {
                years = [new Date().getFullYear()];
                for (var i = 1; i < 10; i++) {
                    years.push(years[0] + i);
                }
            } else {
                years = [new Date().getFullYear()];
                for (var i = 1; i < 3; i++) {
                    years.push(years[0] - i);
                }
            }

            return years;
        },
        getMonths() {
            // Get all the months as numbers
            let months = [];
            for (var i = 1; i <= 12; i++) {
                // Put a zero in front of 1 digit numbers (1-9)
                months.push(("0" + i).slice(-2));
            }
            return months;
        },
        getDays() {
            let days = [];
            let numOfDaysInMonth = null;

            // According to the year and month selected in the select boxes, we check how many days that year and month had
            if (this.year && this.month) {
                numOfDaysInMonth = new Date(this.year, this.month, 0).getDate();
                if (this.v$.$model > numOfDaysInMonth) {
                    this.v$.$model = null;
                }
            } else {
                numOfDaysInMonth = 31;
            }

            for (var i = 1; i <= numOfDaysInMonth; i++) {
                // Create and push list with 0 in front of 1 digit numbers (1-9)
                days.push(("0" + i).slice(-2));
            }
            return days;
        },
    },
};
</script>
