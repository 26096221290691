<template>
    <div class="field d-flex" :class="configStore.config.formStyle === 'old' && fieldName !== 'CHOOSE_LANGUAGE' ? 'old-form-group' : 'modern-form-group'">
        <div v-for="(option, index) in options" :key="option" class="form-field color custom-options" :class="{ success: modelValue === option, 'me-1': index + 1 !== options.length }" @click="$emit('update:modelValue', option)">{{ getDictionary(option) }}</div>
        <label :for="fieldName" class="small-select-label notEmpty">{{ getDictionary(fieldName) }}</label>
    </div>
</template>

<script>
export default {
    name: "select-field-component",
    props: {
        modelValue: String | Object,
        fieldName: String,
        options: Array,
    },
    emits: ["update:modelValue"],
};
</script>
