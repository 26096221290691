import { createCookie, getCookie } from "../../helpers/helpers";
import { setRefreshTokenTimer, logOut } from "../../helpers/servicesHelpers";
import {
    locationQuery,
    brandCountriesQuery,
    lobbiesQuery,
    balancesQuery,
    userQuery,
    userExistsQuery,
    gameSessionConnectionQuery,
    gameRoundConnectionQuery,
    lastSucceededDepositQuery,
    paymentConnectionQuery,
    userLimitsQuery,
    documentConnectionQuery,
    userBonusConnectionQuery,
    userDepositBonusesQuery,
    paymentOptionsQuery,
    userCampaignBonusesQuery,
    userAchievementPathProgressQuery,
    shopItemConnectionQuery,
    liveGameStatsQuery,
    recentWinningsQuery,
    userBoxQuery,
} from "./gqlTmaQueries";
import {
    startGameMutation,
    loginMutation,
    verifyWithAuthMutation,
    refreshTokenMutation,
    createUserMutation,
    updateUserMutation,
    changePasswordMutation,
    createLockMutation,
    setUserLimitsMutation,
    createUploadUriMutation,
    provideDocumentMutation,
    activateUserBonusMutation,
    cancelUserBonusMutation,
    recoverPasswordMutation,
    resetPasswordMutation,
    createPaymentMutation,
    cancelPaymentMutation,
    purchaseShopItemsMutation,
    cancelUserLimitMutation,
    openUserBoxMutation,
} from "./gqlTmaMutations";
import { axiosTmaInstance, errorHandling, tmaErrorHandling } from "../axiosInstances";
import { pushGtmEvent } from "../../helpers/gtm_helpers";

const getLocation = (callsStore, myStore, router, skin, perCallsStore) => {
    return axiosTmaInstance({
        method: "post",
        data: {
            query: locationQuery(),
        },
    })
        .then(function (response) {
            if (response.data.data.location) {
                callsStore.setLocationObject(response.data.data.location);
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getLocation");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getLocation");
        });
};

const getBrandCountries = (perCallsStore, skin, myStore, callsStore, router) => {
    if (perCallsStore.countriesArray.length === 0) {
        return axiosTmaInstance({
            method: "post",
            data: {
                query: brandCountriesQuery(skin),
            },
        })
            .then(function (response) {
                if (response.data.data.brandCountries) {
                    perCallsStore.setCountriesArray(response.data.data.brandCountries);
                } else {
                    return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getBrandCountries");
                }
            })
            .catch(function (error) {
                return errorHandling(error, myStore, "getBrandCountries");
            });
    }
};

const getLobbies = (perCallsStore, skin, id, myStore, callsStore, router) => {
    return axiosTmaInstance({
        method: "post",
        data: {
            query: lobbiesQuery(skin, id),
        },
    })
        .then(function (response) {
            if (response.data.data.lobbies) {
                return response.data.data.lobbies;
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getLobbies");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getLobbies");
        });
};

const startGame = (router, configStore, game, callsStore, myStore, perCallsStore) => {
    const mode = myStore.loggedIn ? "REAL" : "FUN";
    const baseUri = document.location.origin;
    const homeUri = document.location.origin;
    const depositUri = `${document.location.origin}/payments/deposit`;
    const redirectUris = `{baseUri: "${baseUri}", homeUri: "${homeUri}", depositUri: "${depositUri}"}`;

    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${getCookie("accessToken")}`,
        },
        data: {
            query: startGameMutation(configStore.config.skin, game.slug, mode, redirectUris),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.startGame.properties.scriptUri && game.slug === "altenar") {
                callsStore.initSportsBook(response.data.data.startGame.properties, configStore, router, myStore);
            } else if (response.data.data && response.data.data.startGame.properties.uri) {
                callsStore.setGameUri(response.data.data.startGame.properties.uri, game, router, myStore);
            } else {
                myStore.setDisabledGameThumbnails(false);
                return tmaErrorHandling(response, myStore, callsStore, router, configStore.config.skin, perCallsStore, "startGame");
            }
        })
        .catch(function (error) {
            myStore.setDisabledGameThumbnails(false);
            return errorHandling(error, myStore, "startGame");
        });
};

function login(config, username, password, iovationBlackbox, callsStore, myStore, router, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        data: {
            query: loginMutation(config.skin, username, password, iovationBlackbox),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.login && response.data.data.login.accessToken && response.data.data.login.expiresAt && response.data.data.login.refreshToken && response.data.data.login.userId) {
                createCookie("accessToken", response.data.data.login.accessToken, 0.04);
                createCookie("expiresAt", response.data.data.login.expiresAt, 0.04);
                createCookie("refreshToken", response.data.data.login.refreshToken, 0.04);
                createCookie("userId", response.data.data.login.userId, 0.04);
                getUser(response.data.data.login.accessToken, response.data.data.login.userId, callsStore, myStore, router, config.skin, perCallsStore).then(() => {
                    getBalances(response.data.data.login.accessToken, response.data.data.login.userId, callsStore, myStore, router, config.skin, perCallsStore);
                });
                myStore.setLoggedIn(true);
                setRefreshTokenTimer(response.data.data.login.expiresAt, response.data.data.login.refreshToken, response.data.data.login.accessToken, response.data.data.login.userId, callsStore, myStore, router, config.skin, perCallsStore);
                if (getCookie("affiliateId") !== "") {
                    pushGtmEvent({ event: "GA4_TRIGGER", skin: config.skin, affiliateId: getCookie("affiliateId"), eventName: "Login_Success" });
                }
                return { success: true };
            } else {
                if (getCookie("affiliateId") !== "") {
                    pushGtmEvent({ event: "GA4_TRIGGER", skin: config.skin, affiliateId: getCookie("affiliateId"), eventName: "Login_Error" });
                }
                return tmaErrorHandling(response, myStore, callsStore, router, config.skin, perCallsStore, "login");
            }
        })
        .catch(function (error) {
            if (getCookie("affiliateId") !== "") {
                pushGtmEvent({ event: "GA4_TRIGGER", skin: config.skin, affiliateId: getCookie("affiliateId"), eventName: "Login_Error" });
            }
            return errorHandling(error, myStore, "login");
        });
}

function verifyWithAuth(config, code, callsStore, myStore, router, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        data: {
            query: verifyWithAuthMutation(config.skin, code),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.verifyWithAuth && response.data.data.verifyWithAuth.accessToken && response.data.data.verifyWithAuth.expiresAt && response.data.data.verifyWithAuth.refreshToken && response.data.data.verifyWithAuth.userId) {
                createCookie("accessToken", response.data.data.verifyWithAuth.accessToken, 0.04);
                createCookie("expiresAt", response.data.data.verifyWithAuth.expiresAt, 0.04);
                createCookie("refreshToken", response.data.data.verifyWithAuth.refreshToken, 0.04);
                createCookie("userId", response.data.data.verifyWithAuth.userId, 0.04);
                getUser(response.data.data.verifyWithAuth.accessToken, response.data.data.verifyWithAuth.userId, callsStore, myStore, router, config.skin, perCallsStore).then(() => {
                    getBalances(response.data.data.verifyWithAuth.accessToken, response.data.data.verifyWithAuth.userId, callsStore, myStore, router, config.skin, perCallsStore);
                });
                myStore.setLoggedIn(true);
                setRefreshTokenTimer(response.data.data.verifyWithAuth.expiresAt, response.data.data.verifyWithAuth.refreshToken, response.data.data.verifyWithAuth.accessToken, response.data.data.verifyWithAuth.userId, callsStore, myStore, router, config.skin, perCallsStore);

                // GTM - REGISTER Trigger
                if (config.blueMediaCampaignId) {
                    pushGtmEvent({ event: "BLUE_REGISTER_TRIGGER", skin: config.skin, affiliateId: getCookie("affiliateId"), blueMediaCampaignId: config.blueMediaCampaignId });
                }
                if (getCookie("affiliateId") !== "") {
                    pushGtmEvent({ event: "GA4_TRIGGER", skin: config.skin, affiliateId: getCookie("affiliateId"), eventName: "Register_Verification_Success" });
                }

                return { success: true };
            } else {
                if (getCookie("affiliateId") !== "") {
                    pushGtmEvent({ event: "GA4_TRIGGER", skin: config.skin, affiliateId: getCookie("affiliateId"), eventName: "Register_Verification_Error" });
                }
                return tmaErrorHandling(response, myStore, callsStore, router, config.skin, perCallsStore, "verifyWithAuth");
            }
        })
        .catch(function (error) {
            if (getCookie("affiliateId") !== "") {
                pushGtmEvent({ event: "GA4_TRIGGER", skin: config.skin, affiliateId: getCookie("affiliateId"), eventName: "Register_Verification_Error" });
            }
            return errorHandling(error, myStore, "verifyWithAuth");
        });
}

function doRefreshToken(refreshToken, callsStore, myStore, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        data: {
            query: refreshTokenMutation(refreshToken),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.refreshToken && response.data.data.refreshToken.accessToken && response.data.data.refreshToken.expiresAt && response.data.data.refreshToken.refreshToken) {
                createCookie("accessToken", response.data.data.refreshToken.accessToken, 0.04);
                createCookie("expiresAt", response.data.data.refreshToken.expiresAt, 0.04);
                createCookie("refreshToken", response.data.data.refreshToken.refreshToken, 0.04);
                createCookie("userId", getCookie("userId"), 0.04);
                setRefreshTokenTimer(response.data.data.refreshToken.expiresAt, response.data.data.refreshToken.refreshToken, response.data.data.refreshToken.accessToken, getCookie("userId"), callsStore, myStore, router, skin, perCallsStore);
            } else {
                if (response.data.errors[0].message.indexOf("invalid signature") > -1 || response.data.errors[0].message.indexOf("invalid token")) {
                    logOut(callsStore, myStore, router, false, skin, perCallsStore);
                }
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "doRefreshToken");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "doRefreshToken");
        });
}

function getUser(accessToken, userId, callsStore, myStore, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: userQuery(userId),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.user) {
                callsStore.setUserObject(response.data.data.user);
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getUser");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getUser");
        });
}

function getBalances(accessToken, userId, callsStore, myStore, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: balancesQuery(userId),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.balances) {
                callsStore.setBalanceObjects(response.data.data.balances);
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getBalances");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getBalances");
        });
}

function userExists(skin, email, phone, myStore) {
    return axiosTmaInstance({
        method: "post",
        data: {
            query: userExistsQuery(skin, email, phone),
        },
    })
        .then(function (response) {
            if (response.data.data) {
                // Inverting true to false for VueAlidate validation purposes
                return response.data.data.userExists ? false : true;
            } else if (response.data.errors) {
                // If something is wrong with the call, will skip the userExists checking
                return true;
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "userExists");
        });
}

function register(queryInput, myStore, callsStore, router, config, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        data: {
            query: createUserMutation(queryInput),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.createUser) {
                if (getCookie("affiliateId") !== "") {
                    pushGtmEvent({ event: "GA4_TRIGGER", skin: config.skin, affiliateId: getCookie("affiliateId"), eventName: "Register_Success_Not_Verified" });
                }
                return { success: true };
            } else {
                if (getCookie("affiliateId") !== "") {
                    pushGtmEvent({ event: "GA4_TRIGGER", skin: config.skin, affiliateId: getCookie("affiliateId"), eventName: "Register_Error" });
                }
                return tmaErrorHandling(response, myStore, callsStore, router, config.skin, perCallsStore, "register");
            }
        })
        .catch(function (error) {
            if (getCookie("affiliateId") !== "") {
                pushGtmEvent({ event: "GA4_TRIGGER", skin: config.skin, affiliateId: getCookie("affiliateId"), eventName: "Register_Error" });
            }
            return errorHandling(error, myStore, "register");
        });
}

function updateUser(queryInput, myStore, accessToken, callsStore, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: updateUserMutation(queryInput),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.updateUser) {
                return { success: true };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "updateUser");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "updateUser");
        });
}

function changePassword(userId, password, accessToken, myStore, callsStore, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: changePasswordMutation(userId, password),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.changePassword) {
                return { success: true };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "changePassword");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "changePassword");
        });
}

function createLock(queryInput, accessToken, myStore, callsStore, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: createLockMutation(queryInput),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.createLock) {
                return { success: true };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "createLock");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "createLock");
        });
}

function setUserLimits(queryInput, accessToken, myStore, callsStore, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: setUserLimitsMutation(queryInput),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.setUserLimits) {
                callsStore.setLimitsArray(response.data.data.setUserLimits.userLimits);
                return { success: true, data: response.data.data.setUserLimits.userLimits };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "setUserLimits");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "setUserLimits");
        });
}

function lastSucceededDeposit(userId, myStore, callsStore, accessToken, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: lastSucceededDepositQuery(userId),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.paymentConnection.edges) {
                return { success: true, data: response.data.data.paymentConnection.edges };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "lastSucceededDeposit");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "lastSucceededDeposit");
        });
}

function paymentConnection(queryData, forceSearch, myStore, callsStore, accessToken, router, skin, perCallsStore) {
    if (callsStore.paymentConnectionsArray && callsStore.paymentConnectionsArray.length !== 0 && forceSearch === false) {
        return new Promise((resolve) => resolve({ success: true, data: callsStore.paymentConnectionsArray }));
    }
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: paymentConnectionQuery(queryData),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.paymentConnection.edges) {
                callsStore.setPaymentConnectionsArray(response.data.data.paymentConnection.edges);
                return { success: true, data: response.data.data.paymentConnection.edges };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "paymentConnection");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "paymentConnection");
        });
}

function cancelPayment(paymentId, myStore, accessToken, callsStore, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: cancelPaymentMutation(paymentId),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.cancelPayment) {
                return { success: true, data: response.data.data.cancelPayment };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "cancelPayment");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "cancelPayment");
        });
}

function gameSessionConnection(queryData, forceSearch, myStore, callsStore, accessToken, router, skin, perCallsStore) {
    if (callsStore.gameSessionConnectionArray && callsStore.gameSessionConnectionArray.length !== 0 && forceSearch === false) {
        return new Promise((resolve) => resolve({ success: true, data: callsStore.gameSessionConnectionArray }));
    }
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: gameSessionConnectionQuery(queryData),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.gameSessionConnection.edges) {
                callsStore.setGameSessionConnectionArray(response.data.data.gameSessionConnection.edges);
                return { success: true, data: response.data.data.gameSessionConnection.edges };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "gameSessionConnection");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "gameSessionConnection");
        });
}

function getGameRounds(totalRounds, gameSessionId, userId, myStore, callsStore, accessToken, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: gameRoundConnectionQuery(totalRounds, gameSessionId, userId),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.gameRoundConnection.edges) {
                callsStore.setGameRoundsConnectionArray(response.data.data.gameRoundConnection.edges);
                return { success: true, data: response.data.data.gameRoundConnection.edges };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getGameRounds");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getGameRounds");
        });
}

function getLimits(userId, forceSearch, myStore, callsStore, accessToken, router, skin, perCallsStore) {
    if (callsStore.limitsArray && callsStore.limitsArray.length !== 0 && forceSearch === false) {
        return new Promise((resolve) => resolve({ success: true, data: callsStore.limitsArray }));
    }
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: userLimitsQuery(userId),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.userLimits) {
                callsStore.setLimitsArray(response.data.data.userLimits);
                return { success: true, data: response.data.data.userLimits };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getLimits");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getLimits");
        });
}

function getDocuments(userId, forceSearch, myStore, callsStore, accessToken, router, skin, perCallsStore) {
    if (callsStore.documentsArray && callsStore.documentsArray.length !== 0 && forceSearch === false) {
        return new Promise((resolve) => resolve({ success: true, data: callsStore.documentsArray }));
    }
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: documentConnectionQuery(userId),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.documentConnection.edges) {
                callsStore.setDocumentsArray(response.data.data.documentConnection.edges);
                return { success: true, data: response.data.data.documentConnection.edges };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getDocuments");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getDocuments");
        });
}

function createUploadUri(userId, myStore, accessToken, callsStore, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: createUploadUriMutation(userId),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.createUploadUri) {
                return { success: true, data: response.data.data.createUploadUri };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "uploadFile");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "uploadFile");
        });
}

function linkUserDocumentToFile(userId, type, path, accessToken, myStore, callsStore, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: provideDocumentMutation(userId, type, path),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.provideDocument) {
                return { success: true, data: response.data.data.provideDocument.document };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "linkUserDocumentToFile");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "linkUserDocumentToFile");
        });
}

function getBonuses(userId, forceSearch, myStore, callsStore, accessToken, router, skin, perCallsStore) {
    if (callsStore.bonusesArray && callsStore.bonusesArray.length !== 0 && forceSearch === false) {
        return new Promise((resolve) => resolve({ success: true, data: callsStore.bonusesArray }));
    }

    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: userBonusConnectionQuery(userId),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.userBonusConnection.edges) {
                callsStore.setBonusesArray(response.data.data.userBonusConnection.edges);
                return { success: true, data: response.data.data.userBonusConnection.edges };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getBonuses");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getBonuses");
        });
}

function getDepositBonuses(userId, forceSearch, myStore, callsStore, accessToken, router, skin, perCallsStore) {
    if (callsStore.depositBonusesArray && callsStore.depositBonusesArray.length !== 0 && forceSearch === false) {
        return new Promise((resolve) => resolve({ success: true, data: callsStore.depositBonusesArray }));
    }
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: userDepositBonusesQuery(userId),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.userDepositBonuses) {
                callsStore.setDepositBonusesArray(response.data.data.userDepositBonuses);
                return { success: true, data: response.data.data.userDepositBonuses };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getDepositBonuses");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getDepositBonuses");
        });
}

function enableBonus(userId, userBonusId, accessToken, myStore, callsStore, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: activateUserBonusMutation(userId, userBonusId),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.activateUserBonus) {
                return { success: true, data: response.data.data.activateUserBonus.userBonus };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "enableBonus");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "enableBonus");
        });
}

function cancelBonus(userId, userBonusId, accessToken, myStore, callsStore, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: cancelUserBonusMutation(userId, userBonusId),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.cancelUserBonus) {
                return { success: true, data: response.data.data.cancelUserBonus.userBonus };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "cancelBonus");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "cancelBonus");
        });
}

function recoverPassword(skin, email, myStore, callsStore, router, perCallsStore) {
    const redirectUri = `${document.location.origin}/reset-password/:code`;

    return axiosTmaInstance({
        method: "post",
        data: {
            query: recoverPasswordMutation(skin, email, redirectUri),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.recoverPassword) {
                return { success: true, data: response.data.data.recoverPassword };
            } else {
                return tmaErrorHandling(response, myStore, "recoverPassword");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, callsStore, router, skin, perCallsStore, "recoverPassword");
        });
}

function resetPassword(skin, password, hash, callsStore, myStore, router, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        data: {
            query: resetPasswordMutation(skin, password, hash),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.resetPassword && response.data.data.resetPassword.accessToken && response.data.data.resetPassword.expiresAt && response.data.data.resetPassword.refreshToken && response.data.data.resetPassword.userId) {
                createCookie("accessToken", response.data.data.resetPassword.accessToken, 0.04);
                createCookie("expiresAt", response.data.data.resetPassword.expiresAt, 0.04);
                createCookie("refreshToken", response.data.data.resetPassword.refreshToken, 0.04);
                createCookie("userId", response.data.data.resetPassword.userId, 0.04);
                getUser(response.data.data.resetPassword.accessToken, response.data.data.resetPassword.userId, callsStore, myStore, router, skin, perCallsStore).then(() => {
                    getBalances(response.data.data.resetPassword.accessToken, response.data.data.resetPassword.userId, callsStore, myStore, router, skin, perCallsStore);
                });
                myStore.setLoggedIn(true);
                setRefreshTokenTimer(response.data.data.resetPassword.expiresAt, response.data.data.resetPassword.refreshToken, response.data.data.resetPassword.accessToken, response.data.data.resetPassword.userId, callsStore, myStore, router, skin, perCallsStore);
                return { success: true };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "resetPassword");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "resetPassword");
        });
}

function getPaymentOptions(userId, type, currencyCode, accessToken, myStore, callsStore, router, skin, perCallsStore) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: paymentOptionsQuery(userId, type, currencyCode),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.paymentOptions) {
                if (type === "DEPOSIT") {
                    callsStore.setDepositPaymentOptions(response.data.data.paymentOptions);
                } else {
                    callsStore.setWithdrawPaymentOptions(response.data.data.paymentOptions);
                }

                return { success: true, data: response.data.data.paymentOptions };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getPaymentOptions");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getPaymentOptions");
        });
}

function createPayment(queryInput, myStore, accessToken, callsStore, router, config, perCallsStore, type) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: createPaymentMutation(queryInput),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.createPayment) {
                return { success: true, data: response.data.data.createPayment };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, config.skin, perCallsStore, "createPayment");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "createPayment");
        });
}

function getUserCampaignBonuses(userId, skin, myStore, accessToken, perCallsStore, callsStore, router) {
    if (callsStore.userCampaignsList && callsStore.userCampaignsList.length === 0) {
        return axiosTmaInstance({
            method: "post",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                query: userCampaignBonusesQuery(userId),
            },
        })
            .then(function (response) {
                if (response.data.data && response.data.data.userCampaignBonuses) {
                    let campaignIdList = [];
                    response.data.data.userCampaignBonuses.map((campaign) => {
                        if (campaign.campaign.campaignId && campaign.campaign.brandId === skin && campaign.campaign.status === "ACTIVE") {
                            campaignIdList.push(campaign.campaign.campaignId);
                        }
                    });
                    callsStore.setUserCampaignsList(campaignIdList);
                    perCallsStore.setUserCampaignsList(campaignIdList);
                    return { success: true, campaignIdList };
                } else {
                    return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getUserCampaignBonuses");
                }
            })
            .catch(function (error) {
                return errorHandling(error, myStore, "getUserCampaignBonuses");
            });
    } else {
        return new Promise((resolve) => resolve({ success: true, campaignIdList: callsStore.userCampaignsList }));
    }
}

function getVipProgress(userId, skin, myStore, accessToken, perCallsStore, callsStore, router) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: userAchievementPathProgressQuery(userId),
        },
    })
        .then(function (response) {
            // console.log("getVipProgress");
            // console.log(response.data.data);
            // console.log("--------------");

            if (response.data.data && response.data.data.userAchievementPathProgress) {
                return { success: true, response: response.data.data.userAchievementPathProgress };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getVipProgress");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getVipProgress");
        });
}

function getShopItems(skin, myStore, perCallsStore, callsStore, router) {
    if (perCallsStore.shopItemsArray && perCallsStore.shopItemsArray.length !== 0) {
        return new Promise((resolve) => resolve({ success: true, data: perCallsStore.shopItemsArray }));
    }
    return axiosTmaInstance({
        method: "post",
        data: {
            query: shopItemConnectionQuery(skin),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.shopItemConnection && response.data.data.shopItemConnection.edges.length > 0) {
                perCallsStore.setShopItemsArray(response.data.data.shopItemConnection.edges);
                return { success: true, data: response.data.data.shopItemConnection.edges };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getShopItems");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getShopItems");
        });
}

function purchaseShopItem(shopItemIds, accessToken, skin, myStore, perCallsStore, callsStore, router) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: purchaseShopItemsMutation(shopItemIds),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.purchaseShopItems) {
                return { success: true, data: response.data.data.purchaseShopItems };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "purchaseShopItem");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "purchaseShopItem");
        });
}

function cancelUserLimit(userId, userLimitId, accessToken, skin, myStore, perCallsStore, callsStore, router) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            query: cancelUserLimitMutation(userId, userLimitId),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.cancelUserLimit.userLimit.userLimitId) {
                return { success: true, data: response.data.data.cancelUserLimit.userLimit.userLimitId };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "purchaseShopItem");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "cancelUserLimit");
        });
}

function getLiveGameStats(skin, gameIds, myStore, perCallsStore, callsStore, router) {
    return axiosTmaInstance({
        method: "post",
        data: {
            query: liveGameStatsQuery(skin, gameIds),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.liveGameStats) {
                callsStore.setLiveGameStatsArray(response.data.data.liveGameStats);
                return { success: true, data: response.data.data.liveGameStats };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getLiveGameStats");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getLiveGameStats");
        });
}

function getRecentWinnings(skin, orderByField, date, myStore, perCallsStore, callsStore, router) {
    return axiosTmaInstance({
        method: "post",
        data: {
            query: recentWinningsQuery(skin, orderByField, date),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.recentWinConnection.edges) {
                if (orderByField === "createdAt") {
                    callsStore.setRecentWinningsArray(response.data.data.recentWinConnection.edges);
                }
                return { success: true, data: response.data.data.recentWinConnection.edges };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getRecentWinnings");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getRecentWinnings");
        });
}

function getUserBox(skin, myStore, perCallsStore, callsStore, router) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${getCookie("accessToken")}`,
        },
        data: {
            query: userBoxQuery(skin, getCookie("userId")),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.userBoxConnection.edges) {
                let activeUserBoxes = [];
                response.data.data.userBoxConnection.edges.map((box) => {
                    if (box.node.status === "ACTIVE") {
                        activeUserBoxes.push(box.node);
                    }
                });
                perCallsStore.setUserActiveBoxesArray(activeUserBoxes);
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "getUserBox");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getUserBox");
        });
}

function openUserBox(skin, myStore, perCallsStore, callsStore, router) {
    return axiosTmaInstance({
        method: "post",
        headers: {
            Authorization: `Bearer ${getCookie("accessToken")}`,
        },
        data: {
            query: openUserBoxMutation(perCallsStore.userActiveBoxesArray[0].userBoxId),
        },
    })
        .then(function (response) {
            if (response.data.data && response.data.data.openUserBox.userBox.reward) {
                return { success: true, data: response.data.data.openUserBox.userBox.reward };
            } else {
                return tmaErrorHandling(response, myStore, callsStore, router, skin, perCallsStore, "openUserBox");
            }
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "openUserBox");
        });
}

export {
    getLocation,
    getBrandCountries,
    getLobbies,
    startGame,
    login,
    verifyWithAuth,
    doRefreshToken,
    getBalances,
    getUser,
    userExists,
    register,
    updateUser,
    changePassword,
    createLock,
    setUserLimits,
    lastSucceededDeposit,
    paymentConnection,
    gameSessionConnection,
    getGameRounds,
    getLimits,
    getDocuments,
    createUploadUri,
    linkUserDocumentToFile,
    getBonuses,
    getDepositBonuses,
    enableBonus,
    cancelBonus,
    recoverPassword,
    resetPassword,
    getPaymentOptions,
    createPayment,
    cancelPayment,
    getUserCampaignBonuses,
    getVipProgress,
    getShopItems,
    purchaseShopItem,
    cancelUserLimit,
    getLiveGameStats,
    getRecentWinnings,
    getUserBox,
    openUserBox,
};
