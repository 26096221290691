export default [
    "2243",
    "2244",
    "2245",
    "2253",
    "2266",
    "2267",
    "2273",
    "2274",
    "2275",
    "2289",
    "2291",
    "2491",
    "2492",
    "2493",
    "2495",
    "2496",
    "2497",
    "2498",
    "2511",
    "2512",
    "2513",
    "2514",
    "2515",
    "2516",
    "2517",
    "2518",
    "2521",
    "2522",
    "2523",
    "2524",
    "2525",
    "2526",
    "2531",
    "2532",
    "2533",
    "2541",
    "2542",
    "2543",
    "2544",
    "2545",
    "2546",
    "2547",
    "2548",
    "2551",
    "2552",
    "2553",
    "2554",
    "2555",
    "2561",
    "2562",
    "2563",
    "2564",
    "2565",
    "2566",
    "2571",
    "2572",
    "2573",
    "2574",
    "2581",
    "2582",
    "2583",
    "2584",
    "2585",
    "2586",
    "2587",
    "2591",
    "2592",
    "2593",
    "2594",
    "2595",
    "2596",
    "2597",
    "2641",
    "2681",
    "3595",
];
